$(document).ready(function () {

    $('.open-popup-link').magnificPopup({
        type: "inline",
        preloader: false,
        closeMarkup: `
         <button title="Close (Esc)" type="button" class="mfp-close">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="21.5776" height="1.4385" transform="matrix(0.704001 0.710199 -0.704001 0.710199 7.7627 6.90393)" fill="#0E0F10"/>
            <rect width="21.5776" height="1.4385" transform="matrix(0.704001 -0.710199 0.704001 0.710199 7.04688 22.0743)" fill="#0E0F10"/>
            </svg>
        </button>`,
        removalDelay: 500,
        callbacks: {
            beforeOpen: function () {
                this.st.mainClass = this.st.el.attr("data-effect");
                if ($(window).width() < 700) {
                    this.st.focus = false;
                }
            },
        },
    });
    $(document).on('click', '.mfp-close', function (e) {
        e.preventDefault();
        $.magnificPopup.close();
    });
});